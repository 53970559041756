import { ref } from 'vue'
import { api } from '@/api/useAxios'
interface Num {
  hwNum: number;
  plNum: number;
}

interface DisplayNum {
  hwCnt: number;
  plCnt: number;
  scCnt: number;
  score: number;
  unHwCnt: number;
  unreadCnt: number;
  activityCnt: number;
  orderCnt: number;
}

export default () => {
  const numObj = ref<DisplayNum>({
    hwCnt: 0,
    plCnt: 0,
    scCnt: 0,
    score: 0,
    unHwCnt: 0,
    unreadCnt: 0,
    activityCnt: 0,
    orderCnt: 0
  })

  const redDotCount = ref<Num>({
    hwNum: 0,
    plNum: 0
  })

  const fetchNumObj = () => {
    api.get('/homework/qryAllMyData').then(r => {
      numObj.value = r
    })
    api.get('/homework/qryHomeworkAndCommentNum').then(r => {
      redDotCount.value = r
    })
  }

  fetchNumObj()

  return {
    numObj,
    redDotCount
  }
}

export {
  Num,
  DisplayNum
}
