
import { defineComponent, PropType } from 'vue'
import { MenuList } from '@/components/MomaiMenu/types'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    menu: {
      type: Object as PropType<MenuList>
    }
  },
  setup: () => {
    const router = useRouter()
    return {
      gotoPage: (to: string) => {
        if(/^(https?:|mailto:|tel:)/.test(to)){
          window.location.href=to;
        }else{
          router.push(to)
        }
        
      }
    }
  }
})
