
import { user } from '@/states/login'
import useDisplayNum from './useDisplayNum'
import useLists from './useLists'
import { useRouter } from 'vue-router'
import { commentsToMeNum, submittedHomewrokNum } from '@/api/user/student'
import { ref } from 'vue'
import { Dialog } from 'vant'
import UserMenu from '@/views/user/components/UserMenu.vue'
import { getUserId } from '@/utils/auth';
import { getUserInfo } from '@/api/user'

export default {
  components: { 
    UserMenu 
  },
  setup: () => {
    const { numObj, redDotCount } = useDisplayNum()
    const mySubmittedHomeworkNum = ref(0)
    submittedHomewrokNum().then(r => {
      mySubmittedHomeworkNum.value = r
    })
    const commentsToMeNumber = ref(0)
    commentsToMeNum().then(r => {
      commentsToMeNumber.value = r
    })

    const router = useRouter()

    if (!user.value.mobile) {
      Dialog.confirm({
        showCancelButton: false,
        title: '提示',
        allowHtml: true,
        message: '<span>请点击<strong>“系统设置”</strong>绑定手机号，用于平台升级时个人数据保存</span>'
      })
    }
    const userId = getUserId()
    const fanscount = ref(0)
    const followcount = ref(0)
    const qryUserInfo = () => {
      getUserInfo(userId).then((r: any) => {
        followcount.value = r.followingCnt ? r.followingCnt : 0
        fanscount.value = r.fansCnt ? r.fansCnt : 0
      })
    }
    qryUserInfo()

    return {
      mySubmittedHomeworkNum,
      commentsToMeNumber,
      user,
      numObj,
      ...useLists({ user, redDotCount }),
      gotoPage: (to: string) => {
        router.push(to)
      },
      fanscount,
      followcount,
      userId
    }
  }
}
