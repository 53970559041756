import { Num } from './useDisplayNum'
import { computed, ref, Ref } from 'vue'
import { User } from 'momai'
import { createListItem, MenuList } from '@/components/MomaiMenu/types'
import { applicants } from '@/states/login'
import { myUnpaidOrderNum, submittedCourseNum, unreadScoreDetailNum, unsubmittedHomeworkCourseNum } from '@/api/user/student'
import { getPendingHomeworkNum } from '@/api/user/teacher'
import { getDailyUndoNum } from '@/api/entity/daily'


export default ({ redDotCount, user }: {
  redDotCount: Ref<Num>;
  user: Ref<User>;
}) => {
  const userUnsubmittedHomeworkCourseNum = ref(0)
  unsubmittedHomeworkCourseNum().then(n => {
    userUnsubmittedHomeworkCourseNum.value = n
  })

  const myUnpaidOrderNumber = ref(0)
  myUnpaidOrderNum().then(n => {
    myUnpaidOrderNumber.value = n
  })

  const coursesLearnedNum = ref(0)
  submittedCourseNum().then(r => {
    coursesLearnedNum.value = r
  })

  const unreadScoreDetailNumber = ref(0)
  unreadScoreDetailNum().then(r => {
    unreadScoreDetailNumber.value = r
  })

  const myMenu = computed(() => ({
    items: [
      createListItem({
        icon: 'icon-homework',
        title: '我的作业',
        iconColor: 'rgb(0, 90, 171)',
        to: '/user/todo-homeworks',
        num: userUnsubmittedHomeworkCourseNum.value
      }),
      createListItem({
        title: '已学课程',
        icon: 'icon-learned',
        iconColor: 'rgb(221, 159, 83)',
        to: '/user-courses-learned',
        num: coursesLearnedNum.value
      }),
      createListItem({
        title: '我的购买',
        icon: 'icon-buy',
        iconColor: 'rgb(57, 181, 74)',
        num: myUnpaidOrderNumber.value,
        to: '/user/orders'
      }),
      createListItem({
        title: '我的魔豆',
        icon: 'icon-M',
        iconPrefix: 'van-icon',
        to: '/user/score-detail',
        num: unreadScoreDetailNumber.value
      }),
      createListItem({
        title: '调查问卷',
        icon: 'icon-homework',
        iconPrefix: 'van-icon',
        iconColor: 'rgb(113, 150, 159)',
        to: 'https://www.wjx.top/vj/tUKPOWZ.aspx'
      }),
      createListItem({
        title: '系统设置',
        icon: 'icon-setting',
        iconColor: 'rgb(113, 150, 159)',
        to: '/user/system-settings'
      })
    ],
    authority: true
  }))

  const pendingHomeworkNum = ref(0)

  getPendingHomeworkNum().then(r => {
    pendingHomeworkNum.value = r
  })

  const dailyUndoNum = ref(0)
  getDailyUndoNum().then(r => {
    dailyUndoNum.value = r
  })

  

  const teacherMenu = computed<MenuList>(() => {
    const df = user.value.delFlag
    return {
      items: [
        createListItem({
          title: '待批作业',
          icon: 'batch-job',
          iconColor: 'rgb(224, 57, 151)',
          num: pendingHomeworkNum.value,
          to: '/teacher/homework/pending'
        }),
        createListItem({
          title: '已批作业',
          icon: 'icon-batch-operation',
          iconColor: 'rgb(57, 181, 74)',
          to: '/teacher/homework/approved'
        })
      ],
      authority: df === '2' || df === '9',
      title: '导师专区'
    }
  })

  const adminMenu = computed<MenuList>(() => {
    const df = user.value.delFlag
    return {
      title: '管理员专区',
      authority: df === '3' || df === '9',
      items: [
        createListItem({
          title: '评论审核',
          icon: 'icon-review',
          iconPrefix: 'van-icon',
          num: redDotCount.value.plNum,
          to: '/admin/review-comments'
        }),
        createListItem({
          title: '人员管理',
          icon: 'icon-person-manage',
          to: '/admin/user-list',
          num: applicants.value
        }),
        createListItem({
          title: '班级管理',
          icon: 'class-manage',
          to: '/admin/class-list'
        }),
        createListItem({
          title: '课程管理',
          icon: 'course-manage',
          to: '/admin/course-series-list'
        }),
        createListItem({
          title: '活动产品',
          icon: 'active-product',
          to: '/admin/sales/product-list'
        }),
        createListItem({
          title: '标签管理',
          icon: 'tag-manage',
          to: '/admin/tag-list',
          iconPrefix: 'van-icon'
        }),
        createListItem({
          title: '订单管理',
          icon: 'order-manage',
          to: '/admin/order-list'
        }),
        createListItem({
          title: '打卡设置',
          icon: 'icon-clock-setting',
          to: '/admin/daily/edit',
          iconPrefix: 'van-icon'
        }),
        createListItem({
          title: '打卡审核',
          icon: 'icon-clock-review',
          to: '/admin/daily/audit',
          num: dailyUndoNum.value,
          iconPrefix: 'van-icon'
        })
      ]
    }
  })

  return {
    myMenu,
    teacherMenu,
    adminMenu
  }
}
